import {
  Button,
  Checkbox,
  Container,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Table,
} from "@mantine/core";
import {
  IconCalculator,
  IconEdit,
  IconLockOpen,
  IconPlus,
  IconTrashXFilled,
} from "@tabler/icons-react";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "src/services/redux/ReduxStore";
import {
  nullToastUpdateOptions,
  toastCommonError,
  toastDeviceResponseReport,
} from "../../utils/ToastUtils";
import AddDevice from "./AddDevice";
import { useDisclosure } from "@mantine/hooks";
import { generatePath, Link, Navigate, useNavigate } from "react-router-dom";
import { inAppPaths, paths } from "src/App";
import { delay } from "src/utils/Utils";
import EditDevice from "./EditDevice";
import Device, {
  getDeviceDisplayName,
} from "src/services/core/device/models/Device";
import deviceService from "src/services/core/device/Device.service";
import { useTranslation } from "react-i18next";
import physicalDeviceService from "src/services/core/device/PhysicalDevice.service";
import classNames from "classnames";
import Popup from "src/pages/components/Popup";

const DeviceList: () => JSX.Element = () => {
  const [isLoading, { close: turnOffLoading }] = useDisclosure(true);
  const deviceList = useAppSelector((state) => state.deviceList);
  const [selectedDevices, setSelectedSns] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [addModalOpened, { open: openAddModal, close: closeAddModal }] =
    useDisclosure(false);
  const [noSelectedGroup, { open: setNoSelectedGroupTrue }] =
    useDisclosure(false);
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);
  const [toBeEditedDevice, setToBeEditedDevice] = useState<Device>(null as any);
  const { t } = useTranslation(undefined, { keyPrefix: "inApp.deviceList" });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const delayPromise = delay(300);
      const result = await deviceService.loadDeviceListInitial();
      await delayPromise;
      if (!result.success) {
        if (result.code == "no-selected-groups") {
          setNoSelectedGroupTrue();
        } else {
          toastCommonError(result.code);
        }
      }
      turnOffLoading();
    })();
  }, []);

  const deleteDevices = useCallback(async () => {
    const deletePromise = deviceService.deleteDevices(selectedDevices);
    const deleteToastId = toast.loading(t("deleting"), {
      closeButton: true,
      closeOnClick: true,
    });

    const result = await deletePromise;
    if (result.success) {
      toast.update(deleteToastId, {
        ...nullToastUpdateOptions,
        render: t("deleteSuccess"),
        type: "success",
      });
      setSelectedSns([]);
      closeDeleteModal();
      return;
    }

    toastCommonError(result.code, deleteToastId);
  }, [selectedDevices]);

  const openDoors = useCallback(async () => {
    const promise = physicalDeviceService.openDoors(selectedDevices);
    const toastId = toast.loading(t("sendingCommand"), {
      closeButton: true,
      closeOnClick: true,
    });

    const result = await promise;
    const devices = deviceList ?? [];
    if (result.success) {
      toastDeviceResponseReport(deviceList ?? [], result.value, toastId, {
        okSnList: (list) =>
          t("doorOpened", {
            count: list?.length ?? -1,
            devices: list
              .flatMap((sn) => {
                const device = devices.find((d) => d.serialNumber == sn);
                if (device == null) {
                  return sn;
                }
                return getDeviceDisplayName(device);
              })
              .join(", "),
          }),
      });
      return;
    }

    toastCommonError(result.code, toastId);
  }, [selectedDevices]);

  let no = 1;

  const rows = deviceList?.map((device) => (
    <Table.Tr key={device.id}>
      <Table.Td>{no++}</Table.Td>
      <Table.Td>
        {
          <Link
            className="hover:text-[var(--mantine-color-blue-light-color)] focus:text-[var(--mantine-color-blue-light-color)]"
            to={generatePath(paths.inAppPaths.deviceDetail, {
              deviceSn: device.serialNumber,
            })}
          >
            {device.serialNumber}
          </Link>
        }
      </Table.Td>
      <Table.Td>
        <Link
          className="hover:text-[var(--mantine-color-blue-light-color)] focus:text-[var(--mantine-color-blue-light-color)]"
          to={generatePath(paths.inAppPaths.deviceDetail, {
            deviceSn: device.serialNumber,
          })}
        >
          {device.name}
        </Link>
      </Table.Td>
      <Table.Td>
        {device.isOnline == true ? (
          <span className="text-[var(--mantine-color-green-6)] font-semibold">
            {t("table.online")}
          </span>
        ) : (
          <span className="text-gray-500">{t("table.offline")}</span>
        )}
      </Table.Td>
      <Table.Td className={classNames("flex max-w-full flex-wrap")}>
        <Checkbox
          className="m-[2px]"
          size="md"
          onChange={(event) => {
            if (event.currentTarget.checked) {
              setSelectedSns((sns) => [...sns, device.serialNumber]);
            } else {
              setSelectedSns((sns) =>
                sns.filter((d) => d != device.serialNumber)
              );
            }
          }}
        />
        <Button
          variant="light"
          onClick={() => {
            setToBeEditedDevice(device);
            openEditModal();
          }}
          color="yellow"
          className="!h-6 !w-6 !p-0 flex justify-center items-center m-[2px]"
        >
          <IconEdit size={18} />
        </Button>
        <Button
          variant="light"
          onClick={() => {
            navigate(
              generatePath(paths.inAppPaths.deviceDetail, {
                deviceSn: device.serialNumber,
              })
            );
          }}
          color="blue"
          className="!h-6 !w-6 !p-0 flex justify-center items-center m-[2px]"
        >
          <IconCalculator size={18} />
        </Button>
      </Table.Td>
    </Table.Tr>
  ));

  const buttonClassName = "p-0 mr-4 mb-2";

  return noSelectedGroup ? (
    <Navigate to={inAppPaths.groups} />
  ) : (
    <ScrollArea className="w-full h-full">
      <Container size="md" my={40}>
        {errorMessage != null ? (
          errorMessage
        ) : isLoading ? (
          <div className="h-svh w-full">
            <LoadingOverlay
              className=""
              visible={true}
              zIndex={1000}
              // overlayProps={{ radius: "sm", blur: 2 }}
            />
          </div>
        ) : (
          <>
            <div className="my-5 flex max-w-full flex-wrap">
              <Button
                color="green"
                leftSection={<IconPlus size={20} />}
                className={buttonClassName}
                onClick={openAddModal}
              >
                {t("addDevice")}
              </Button>
              <Button
                disabled={selectedDevices.length == 0}
                color="red"
                leftSection={<IconTrashXFilled size={20} stroke={2.5} />}
                className={buttonClassName}
                onClick={openDeleteModal}
              >
                {t("delete")}
              </Button>
              <Button
                disabled={selectedDevices.length == 0}
                color="blue"
                leftSection={<IconLockOpen size={20} />}
                className={buttonClassName}
                onClick={openDoors}
              >
                {t("openDoor")}
              </Button>
            </div>
            {deviceList == null ? (
              <div className="flex justify-center mt-10 text-gray-500">
                {t("getDevicesFail")}
              </div>
            ) : deviceList.length <= 0 ? (
              <div className="flex justify-center mt-10 text-gray-500">
                {t("noDevices")}
              </div>
            ) : (
              <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>{t("table.no")}</Table.Th>
                    <Table.Th>{t("table.serialNumber")}</Table.Th>
                    <Table.Th>{t("table.name")}</Table.Th>
                    <Table.Th>{t("table.status")}</Table.Th>
                    <Table.Th className="">{t("table.action")}</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            )}
            <Modal opened={addModalOpened} onClose={closeAddModal}>
              <AddDevice onFormSubmitSuccess={closeAddModal} />
            </Modal>
            <Modal opened={editModalOpened} onClose={closeEditModal}>
              <EditDevice
                device={toBeEditedDevice}
                onFormSubmitSuccess={closeEditModal}
              />
            </Modal>
            <Popup
              opened={deleteModalOpened}
              onClose={closeDeleteModal}
              prepare={undefined}
              acceptColor="red"
              titleFactory={() => t("deleteTitle")}
              onMainAction={deleteDevices}
              childrenFactory={() => {
                return (
                  <div>
                    {t("deleteWarning", {
                      count: selectedDevices.length,
                    })}
                  </div>
                );
              }}
              acceptNode={t("delete")}
            />
          </>
        )}
      </Container>
    </ScrollArea>
  );
};

export default DeviceList;
