import cx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import {
  Container,
  UnstyledButton,
  Group,
  Text,
  Menu,
  Tabs,
  Burger,
  rem,
  ScrollAreaAutosize,
  Title,
  Modal,
  TextInput,
  Divider,
  Drawer,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconLogout,
  IconHeart,
  IconStar,
  IconMessage,
  IconSettings,
  IconPlayerPause,
  IconTrash,
  IconSwitchHorizontal,
  IconChevronDown,
  IconAlertTriangleFilled,
  IconUser,
  IconUfo,
  IconBrand4chan,
  IconBrandCodepen,
  IconAlertTriangle,
  IconLogin,
  IconLogin2,
  IconWorld,
} from "@tabler/icons-react";
import classes from "./HeaderRoot.module.css";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppSelector } from "src/services/redux/ReduxStore";
import { inAppPaths, paths } from "src/App";
import authService from "src/services/core/auth/Auth.service";
import DeleteAccount from "../../user/DeleteAccount";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import localStorageService from "src/repositories/local-storage/LocalStorageService";
import { defaultLng } from "../../../i18n/i18n";
import { isString } from "src/utils/Utils";
import SideBar from "../SideBar/SideBar";
import { useWindowSize } from "@uidotdev/usehooks";
import classNames from "classnames";
import useIsMobile from "src/pages/utils/isMobileHook";
import resources from "src/pages/utils/resources";

const languages: Array<{ code: string; text: string; countryCode: string }> = [
  {
    code: "vi",
    text: "Tiếng Việt",
    countryCode: "VN",
  },
  {
    code: "en",
    text: "English",
    countryCode: "GB",
  },
];

export function HeaderRoot() {
  const [isMobile] = useIsMobile();
  const tokenPayload = useAppSelector((state) => state.token)?.payload;
  const isSignedIn = tokenPayload?.isAccountActivated == true;
  const navigate = useNavigate();
  const [
    deletAccountShown,
    { open: showDeleteAccount, close: hideDeleteAccount },
  ] = useDisclosure(false);
  const [userMenuOpened, { open: openUserMenu, close: closeUserMenu }] =
    useDisclosure(false);
  // const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: "nav.headerRoot",
  });
  const [navOpened, { open: openNav, close: closeNav, toggle: toggleNav }] =
    useDisclosure(false);

  const logout = useCallback(() => {
    authService.logout();
    navigate(paths.login);
  }, []);

  const setLanguageItem = useCallback(
    (code: string) => {
      i18n.changeLanguage(code);
      localStorageService.storeData("languagePreference", code);
    },
    [i18n]
  );

  const languageItems = useMemo(
    () =>
      languages.map((language) => (
        <div
          key={language.code}
          className={cx([
            " rounded-[4px]",
            { "bg-slate-200": i18n.language == language.code },
          ])}
        >
          <Menu.Item
            onClick={() => setLanguageItem(language.code)}
            leftSection={
              <ReactCountryFlag
                countryCode={language.countryCode}
                style={{
                  width: "2em",
                  height: "2em",
                }}
                svg
              />
            }
          >
            {language.text}
          </Menu.Item>
        </div>
      )),
    [languages, i18n, i18n.language]
  );

  return (
    <div className="overflow-hidden h-screen flex flex-col w-screen">
      <div className="pt-3 bg-[#e9ecef] -border-b-4 border-[#e9ecef] flex-none">
        <Container className="pb-3" size="full">
          <Group justify="space-between">
            <div className="flex space-x-3 items-center">
              {isSignedIn && isMobile && (
                <Burger onClick={toggleNav} size="sm" />
              )}
              <div className="h-9 aspect-square flex items-center justify-center">
                <img src={resources.logoUri}/>
              </div>
              {/* <IconUfo
                size={40}
                radius="xl"
                className="bg-white rounded-full p-1"
              /> */}
              <div className="font-sans text-xl font-black">KT LOCK</div>
            </div>
            <div className="flex items-center h-full">
              {isSignedIn ? (
                <Menu
                  width={160}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                  onClose={() => closeUserMenu}
                  onOpen={() => openUserMenu}
                  withinPortal
                >
                  <Menu.Target>
                    <UnstyledButton
                      title=""
                      className={cx(classes.user, {
                        [classes.userActive]: userMenuOpened,
                      })}
                    >
                      <Group gap={7}>
                        <IconUser
                          fill="#f5cb5c"
                          color="#f5cb5c"
                          radius="xl"
                          size={25}
                          className="bg-white rounded-full p-1 border-black"
                        />
                        {!isMobile && (
                          <>
                            <Text fw={700} size="sm" lh={1}>
                              {tokenPayload.name}
                            </Text>
                            <IconChevronDown
                              style={{ width: rem(12), height: rem(12) }}
                              stroke={1.5}
                            />
                          </>
                        )}
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown className="!z-[5000]">
                    <Menu.Item
                      onClick={showDeleteAccount}
                      leftSection={
                        <IconAlertTriangle
                          style={{ width: rem(16), height: rem(16) }}
                          color="var(--mantine-color-red-filled)"
                          stroke={1.5}
                        />
                      }
                    >
                      {t("deleteAccount")}
                    </Menu.Item>
                    <Menu.Item
                      onClick={logout}
                      leftSection={
                        <IconLogout
                          style={{ width: rem(16), height: rem(16) }}
                          stroke={1.5}
                        />
                      }
                    >
                      {t("logOut")}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ) : (
                <Link
                  to={paths.login}
                  className="font-bold text-sm hover:bg-white focus:bg-white rounded-sm !p-1"
                >
                  <Group /*className="bg-white rounded-full p-1"*/ px={10}>
                    <IconLogin2 radius="xl" size={25} />
                    {!isMobile && t("login")}
                  </Group>
                </Link>
              )}
              <div className="ml-2 flex items-center hover:bg-white focus-within:bg-white rounded-sm">
                <Menu
                  width={160}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                  onClose={() => closeUserMenu}
                  onOpen={() => openUserMenu}
                  withinPortal
                >
                  <Menu.Target>
                    <UnstyledButton className="!p-1">
                      <Group gap={7}>
                        <IconWorld
                          radius="xl"
                          size={25}
                          // color={"#" + "00".repeat(3)}
                          // className="bg-white rounded-full p-1"
                        />
                        {!isMobile && (
                          <div className="w-7">
                            <Text fw={700} size="sm" lh={1}>
                              {(
                                languages.find((v) => v.code == i18n.language)
                                  ?.code ?? defaultLng
                              ).toUpperCase()}
                            </Text>
                          </div>
                        )}
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown className="font-semibold !z-[5000]">
                    {languageItems}
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>
          </Group>
        </Container>
        {/* <Container size="lg" pb={0}>
          {tokenPayload?.isAccountActivated == true ? (
            <Tabs
              defaultValue="Home"
              variant="outline"
              // visibleFrom="xs"
              classNames={{
                root: classes.tabs,
                list: classes.tabsList,
                tab: classes.tab,
              }}
              onChange={onTabClick}
            >
              <Tabs.List>{items}</Tabs.List>
            </Tabs>
          ) : null}
        </Container> */}
      </div>
      <Modal
        size={"lg"}
        centered
        onClose={hideDeleteAccount}
        opened={deletAccountShown}
      >
        <div className="p-5">
          <DeleteAccount />
        </div>
      </Modal>
      {/* TODO: why does it not work when "flex flex-col" is removed? */}
      <div className="flex flex-col flex-1 overflow-hidden h-full w-full">
        <div className="h-full flex flex-row relative w-full">
          {isSignedIn &&
            (isMobile ? (
              <>
                <div
                  className={classNames([
                    "absolute h-full w-full z-[4000] flex flex-row ",
                    { "pointer-events-none": !navOpened },
                    { "pointer-events-auto": navOpened },
                  ])}
                >
                  <div
                    className={classNames([
                      "h-full flex flex-row-reverse",
                      { "w-0": !navOpened },
                      { "w-60": navOpened },
                    ])}
                    style={{
                      transition: "width 300ms",
                    }}
                  >
                    <SideBar onItemClick={closeNav} />
                  </div>
                  <div
                    className={classNames([
                      "h-full flex-1 bg-[#00000066]",
                      { "w-0 opacity-0": !navOpened },
                    ])}
                    style={{
                      transition: "opacity 300ms",
                    }}
                    onClick={closeNav}
                  />
                </div>
              </>
            ) : (
              <SideBar />
            ))}
          <div className="w-svw sm:w-full overflow-hidden">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderRoot;
