import {
  Button,
  Container,
  NumberInput,
  Paper,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  nullToastUpdateOptions,
  toastCommonError,
} from "src/pages/utils/ToastUtils";
import deviceService, {
  getActualPassword,
} from "src/services/core/device/Device.service";

const AddDevice: (props: {
  onFormSubmitSuccess?: () => void;
}) => JSX.Element = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "inApp.deviceList.addDevicePage",
  });

  const form = useForm({
    initialValues: {
      serialNumber: "",
      password: null as string | null,
      name: "",
    },
    validate: {
      serialNumber: (value) =>
        value.trim().length == 16 ? null : t("serialNotLongEnoughError"),
      // : value.trim().length > 0
      // ? null
      // : t("noEmptySerialNumber"),
    },
  });

  const formSubmit = useCallback(
    async (values: typeof form.values) => {
      const addPromise = deviceService.addDevices([
        {
          serialNumber: values.serialNumber.trim(),
          password: getActualPassword(values.password),
          name: values.name ?? "",
        },
      ]);

      const addToastId = toast.loading(t("adding"), {
        closeButton: true,
        closeOnClick: true,
      });

      const result = await addPromise;
      if (result.success) {
        toast.update(addToastId, {
          ...nullToastUpdateOptions,
          render: t("addSuccess"),
          type: "success",
        });

        props.onFormSubmitSuccess?.();
        return;
      }

      if (result.code == "devices-exist") {
        toast.update(addToastId, {
          ...nullToastUpdateOptions,
          render: t("deviceExist") + result.data,
          type: "error",
        });
        return;
      }

      if (result.code == "devices-not-connected") {
        form.setFieldError("serialNumber", t("deviceNotConnected"));
        toast.update(addToastId, {
          ...nullToastUpdateOptions,
          render: t("deviceNotConnected"),
          type: "error",
        });
        return;
      }

      toastCommonError(result.code, addToastId);
    },
    [props.onFormSubmitSuccess]
  );

  return (
    <Container size={420} my={"3vh"}>
      <Title ta="center">{t("title")}</Title>

      <Paper mt={"5vh"} radius="md">
        <form onSubmit={form.onSubmit((values) => formSubmit(values))}>
          <TextInput
            label={t("serialNumberlabel")}
            placeholder="VK-XXXXXXXXXXXXX"
            required
            mt="md"
            spellCheck={false}
            {...form.getInputProps("serialNumber")}
            onChange={(e) => {
              form.setFieldValue(
                "serialNumber",
                e.target.value.substring(0, 16).toUpperCase()
              );
            }}
            rightSection={
              <div className="text-xs mr-2">
                {form.values.serialNumber.length}/16
              </div>
            }
          />
          <NumberInput
            allowLeadingZeros
            isAllowed={(values) => {
              return values.value.length <= 8;
            }}
            label={
              <span>
                {t("passwordLablel")}
                <span className="text-[#999999]">{t("blankIfNoPassword")}</span>
              </span>
            }
            placeholder="XXXXXXXX"
            mt="md"
            allowDecimal={false}
            min={0}
            max={99999999}
            {...form.getInputProps("password")}
            onChange={undefined}
            onValueChange={(values) => {
              form.values.password = values.value;
            }}
          />
          <TextInput
            label={t("nameLabel")}
            placeholder={t("namePlaceholder")}
            mt="md"
            spellCheck={false}
            {...form.getInputProps("name")}
          />
          <Button fullWidth mt="xl" type="submit">
            {t("add")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AddDevice;
